import { CSSProperties } from "react"

type ArrowIconProps = {
    type?: 'right' | 'left' | 'bottom' | 'top',
    style?: CSSProperties,
    width?: number | string
}
export const ArrowIcon = ({ type = 'right', style, width = "0.6em" }: ArrowIconProps) => (
    <svg width={width} height={width} viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg"
        style={{ ...style, ...(type == 'left' ? { transform: 'rotateY(180deg)' } : type == 'bottom' ? { transform: 'rotate(90deg)' } : type == 'top' ? { transform: 'rotate(270deg)' } : null) }}
    >
        <path d="M7.147 8.948L0 1.893L1.868 0L10.936 8.951L1.867 17.878L0.000999451 15.982L7.147 8.948Z" fill="currentColor"></path>
    </svg>
)