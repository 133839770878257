import React, { ReactNode, useEffect } from 'react';
import './styles.less';
import { CloseButton } from '../CustomIcons/CloseButton/web';
import { createPortal } from 'react-dom';

interface ModalProps {
    show: boolean;
    width?: string;
    height?: string;
    background?: string;
    title?: string | ReactNode;
    footer?: ReactNode;
    children: React.ReactNode;
    onClose: () => void;
    classes?: string;
    backdropStyles?: React.CSSProperties;
    bodyStyles?: React.CSSProperties;
    containerStyles?: React.CSSProperties;
    animation?: 'fade' | 'slide';
    showHeader?: boolean;
}

export const Modal: React.FC<ModalProps> = ({ show, width, height, background, title, children, onClose, footer, classes, backdropStyles, animation="fade", bodyStyles, containerStyles, showHeader = true }) => {
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape") {
                handleClose();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const handleClose = () => {
        onClose();
    };

    const modalStyle = {
        width: width || 'auto',
        height: height || 'auto',
    };

    return show ? createPortal(
        <div className={`modal-backdrop modal-open`} onClick={()=>{handleClose()}} style={backdropStyles ?? {}}>
            <div onClick={(event)=>{event.stopPropagation()}} className={"modal " + (background == 'transparent ' ? 'modal-transparent ' : '') + (classes ?? "") + ' ' + animation} style={{...modalStyle, ...(containerStyles ?? {})}}>
               {showHeader && <div className="modal-header">
                    <div className="modal-title">{title}</div>
                    <button className={"close-button " + (background == 'transparent' ? 'button-background' : '')} onClick={handleClose}>
                        <CloseButton />
                    </button>
                </div>}
                <div className="modal-body" style={bodyStyles ?? {}}>
                    {children}
                </div>

                {footer && <div className="modal-footer">
                    {footer}
                </div>}
            </div>
        </div>,
        document.body
    ) : null;

};
