import "./styles.less";

import { Badge } from "antd";
import { CaretFilled } from "shared-components/Components/CustomIcons/CaretFilledIcon/web";
import { BellOutlined } from "shared-components/Components/CustomIcons/BellOutlinedIcon/web";
import UserOutlined from "shared-components/Components/CustomIcons/UserOutlinedIcon/web";
import React, { useState } from "react";
import { Button } from "shared-components/Components/Buttons/web";
import { Logout } from "../../../Components/User/LogOut/web";
import { openAuthModal } from "../../../Components/User/AuthModal/openAuthModal";
import { useUserBox } from "./hook";

import dynamic from "next/dynamic"
import { ButtonGarantíasAlquiler } from "shared-components/Components/ButtonGarantíasAlquiler/web";
const NotificationList = dynamic(
	(): any => import("../../../Components/User/NotificationList/web").then(mod => mod.NotificationList),
	{ ssr: false }
)

export const UserBox = ({ isMobile = false }) => {
	const { main_domain, data, country_code, user, isLoggedIn, dataPP } = useUserBox();
	let urlPublicateProperty = "https://" + main_domain + "/sitio/index.php?mid=propiedades&func=add";

	if (country_code === "ce3") urlPublicateProperty = "https://www.infocasas.com.uy/publicar-alquiler-temporario"

	const IrisButton = (
		<a className={isMobile ? 'header_btn_mobile' : 'header_btn'} href={"https://" + main_domain + "/proyectos/iris"}>
			{"Iris"}
		</a>
	);

	const PublicatePropertyButton = (
		<a className={isMobile ? 'header_btn_mobile' : 'header_btn'} href={urlPublicateProperty} target={country_code == 'ce3' ? '_blank' : '_self'}>
			{"Publicar propiedad"}
		</a>
	);

	const LoginUserButton =
		isMobile ?
			<span style={{ order: -1 }} className="header_btn_mobile" onClick={() => openAuthModal({ defaultModalType: "LogIn" })}>
				{"Ingresar"}
			</span>
			:
			<Button type="primary" onClick={() => openAuthModal({ defaultModalType: "LogIn" })}>
				{"Ingresar"}
			</Button>
		;


	const PublicatePropertyDropdown = <MenuDropDown
		isMobile={isMobile}
		text={"Publicar propiedad"}
		content={dataPP.map((e, i) => (
			<a key={"key_favorite" + i}
				className={isMobile ? 'header_btn_mobile' : ''}
				href={"https://" + main_domain + e.url}>
				{e.title}
			</a>
		))}
	/>

	return (
		<div className={isMobile ? 'userbox-mobile' : 'userbox'}>
			{/* Garantías de Alquiler */}
			{(country_code === "UY" && isLoggedIn && !user?.data?.me?.individual) &&
				<ButtonGarantíasAlquiler isMobile={isMobile} />
			}

			{/* Ir a Iris */}
			{user?.data?.me?.isIris && (country_code != "BR" && country_code != "ce3") && IrisButton}

			{/* Contacto */}
			{country_code === "ce3" &&
				<a className={isMobile ? 'header_btn_mobile' : 'header_btn'} href="/contacto">
					{"Contacto"}
				</a>
			}

			{/* Publicar Propiedad */}
			{country_code === "BR"
				? null
				: isLoggedIn
					? user?.data?.me?.individual && country_code !== "ce3"
						? PublicatePropertyDropdown
						: PublicatePropertyButton
					: country_code === "ce3"
						? PublicatePropertyButton
						: PublicatePropertyDropdown}

			{/* Login  */}
			{isLoggedIn ? <>
				<MenuDropDown
					isMobile={isMobile}
					text={<span>
						<UserOutlined style={{ marginRight: '10px' }} />
						<span> {user?.data?.me.name} </span>
					</span>}
					content={<>
						{data.map((e, i) => (
							<a className={isMobile ? 'header_btn_mobile' : ''} key={"key_user_data " + i} href={"https://" + main_domain + e.url}>{e.title}</a>
						))}
						<Logout className={isMobile ? 'header_btn_mobile' : ''} />
					</>}
				/>
			</> : LoginUserButton}

			{/* Notificaciones  */}
			{isLoggedIn && country_code != "BR" && <>
				<MenuDropDown
					isMobile={isMobile}
					contentClass="noHover dropdown-to-left"
					text={<>
						<Badge
							count={user?.data?.me.unread_notifications}
							overflowCount={9}
							size={"small"}>
							<BellOutlined style={{ marginRight: isMobile ? '10px' : '0px' }} />
							{isMobile ? <>{'Notificaciones'}</> : <></>}
						</Badge>
					</>}
					content={<>
						<NotificationList />
					</>}
				/>
			</>}

		</div>
	);
};

const MenuDropDown = (
	{ text, content, isMobile = false, contentClass = '' }
		: { text: any, content: any, isMobile?: boolean, contentClass?: string }
) => {
	const [open, setOpen] = useState(false)

	return (!isMobile ? <>
		<div className="userbox-dropdown">
			<span className="header_btn">
				{text}
			</span>
			<div className={`userbox-dropdown-content ${contentClass}`}>
				{content}
			</div>
		</div>
	</> : <>
		<span className="header_btn_mobile userbox-dropdown_btnMobile" onClick={() => { setOpen(!open) }}>
			{text}
			<CaretFilled type={open ? "down" : "up"} />
		</span>
		{open &&
			<div className={`userbox-dropdown-content_mobile ${contentClass}`}>
				{content}
			</div>
		}
	</>)



}